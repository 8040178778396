import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import defaultplaceholder from "../Assets/Images/default-placeholder.svg";
import MUltiSelectAdd from "../Assets/Images/multiselct-Add.svg";
import GoogleImg from "../Assets/Images/Google.svg";
import Trustpilot from "../Assets/Images/Trustpilot_ Reviews & Ratings.svg";
import {
  useGetUserdetailsQuery,
  useTSeditBusinessDetailsMutation,
  useTsEditAdditionalDetailsMutation,
  useGetTSServiceCategoryQuery,
  useGetcefeaturesQuery,
  useGetcemenuhighlightQuery,
  useTsEditEcosystemCriteriaMutation,
  useUserEditMutation,
  useGetStatesQuery
} from "../Services/UserAPI";
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Swal from 'sweetalert2';
import FaceBookImg from "../Assets/Images/facebook-color.svg"

const libraries = ["places"];


function CafeDetails({ id, type, userId }) {
  // You now have access to id and type as props
  // const [id, setUserId] = useState(id);
  // const [type, setUserType] = useState(type);
  const { data, refetch } = useGetUserdetailsQuery({ id, type });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedMenu, setselectedMenu] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);
  const [selectedCoverImage, setselectedCoverImage] = useState(null);

  const { data: serviceData } = useGetTSServiceCategoryQuery();
  const { data: featureData } = useGetcefeaturesQuery();
  const { data: menuhighlightData } = useGetcemenuhighlightQuery();

  const { data: statesData } = useGetStatesQuery();

  const googleMapApiKey = "AIzaSyAvbSYD-acd2LVkzccRVijLFJRE7DzQvPA"
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
    libraries,
  });

  const [addressMain, setMainAddress] = useState("");
  const [mainBranchlat, setMainbranchlat] = useState("");
  const [mainBranchlng, setMainbranchlng] = useState("");

  const searchBoxRef = useRef(null);
  const searchBoxMainRef = useRef(null);


  const [submitUserEditForm, { }] =
    useUserEditMutation();

  useEffect(() => {
    if (data && data.additional_detail) {
      // Assuming service_categories might need to be mapped to get IDs
      setSelectedOptions(
        data?.additional_detail?.service_categories?.map(
          (category) => category.id
        ) || []
      );
    }
  }, [data]);

  // useEffect(() => {
  //   // If you want to perform any side effects when id or type changes
  //   console.log("Received ID:", userId, "Received Type:", userType);
  // }, [userId, userType]);



  // State for managing selected options
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);
  const [selectedFeatureseco, setSelectedFeatureseco] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDietryService, setSelectedDietryService] = useState([]);
  const [selectedFSStates, setSelectedFsStates] = useState([]);
  const [selectedTSStates, setSelectedTSStates] = useState([]);
  const [selectedTradeServiceType, setSelectedTradeServiceType] = useState([]);
  const [selectedMinibio, setMinibio] = useState("")
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (data) {
      setSelectedFeatures(data?.ecosystem_criteria?.ce_features.map(category => category.id) || []);
      setSelectedMenuHighlights(data?.ecosystem_criteria?.ce_menu_highlights.map(category => category.id) || []);
      setMinibio(data?.additional_detail?.min_bio)
    }
    const operationalStatus =
      data?.ecosystem_criteria?.ce_operational_status || [];

    const statusIds = operationalStatus.map((status) => status.id);

    const statusMapping = {
      planning: statusIds.includes(1), // ID 1 is for planning
      open: statusIds.includes(2), // ID 2 is for open
      exiting: statusIds.includes(3), // ID 3 is for exiting
    };


    setSelectedOperationalStatus(statusMapping);


    setSelectedOperationalStatus(statusMapping);
  }, [data]);


  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useTSeditBusinessDetailsMutation();

  const [submitEcosystemCriteria, { }] =
    useTsEditEcosystemCriteriaMutation();

  const [submitAdditionalData, { }] = useTsEditAdditionalDetailsMutation();

  // Handlers for checkbox changes
  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Other handlers
  const handleFeatureChangeEco = (id) => {
    setSelectedFeatureseco((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleProcductChange = (id) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDietryServiceChange = (id) => {
    setSelectedDietryService((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleFsStateChange = (id) => {
    setSelectedFsStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleTsStateChange = (id) => {
    setSelectedTSStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChangeTradeServiceType = (id) => {
    setSelectedTradeServiceType((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Managing visibility of different sections
  const [isViewbusinessDetails, setisViewbusinessDetails] = useState(true);
  const handleClickEDitBusinessDetails = (e) => {
    e.preventDefault();
    setisViewbusinessDetails(false);
  };

  const handleClickCancelBusinessDetails = (e) => {
    e.preventDefault();
    setisViewbusinessDetails(true);
  };

  const [isViewonlinePreference, setisViewOnlinePreference] = useState(true);
  const handleClickEDitOnlinePreference = (e) => {
    e.preventDefault();
    setisViewOnlinePreference(false);
  };

  const handleClickCancelOnlinePreference = (e) => {
    e.preventDefault();
    setisViewOnlinePreference(true);
  };

  const [isViewDetails, setisViewDetails] = useState(true);
  const handleClickEDitDetails = (e) => {
    e.preventDefault();
    setisViewDetails(false);
  };

  const handleClickCancelDetails = (e) => {
    e.preventDefault();
    setisViewDetails(true);
  };

  const [isViewAdditionalDetails, setisViewAdditionalDetails] = useState(true);
  const handleClickEDiAdditionalDetails = (e) => {
    e.preventDefault();
    setisViewAdditionalDetails(false);
  };

  const handleClickCancelAdditionalDetails = (e) => {
    e.preventDefault();
    setisViewAdditionalDetails(true);
  };

  const [isViewEcosystemCriteria, setisViewEcosystemCriteria] = useState(true);
  const handleClickEcosystemCriteria = (e) => {
    e.preventDefault();
    setisViewEcosystemCriteria(false);
  };

  const handleClickCancelEcosystemCriteria = (e) => {
    e.preventDefault();
    setisViewEcosystemCriteria(true);
  };

  const [isViewTradesandService, setisViewTradesandService] = useState(true);
  const handleClickEditTradesandService = (e) => {
    e.preventDefault();
    setisViewTradesandService(false);
  };

  const [isViewAccountHolderDetails, setiisViewAccountHolderDetails] =
    useState(true);
  const handleClickEditAccountHolderDetails = (e) => {
    e.preventDefault();
    setiisViewAccountHolderDetails(false);
  };

  const handleClickCancelAccountHolderDetails = (e) => {
    e.preventDefault();
    setiisViewAccountHolderDetails(true);
  };

  const [accountHolderForm, setAccountHolderForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    state: "",
    post_code: "",
  });

  const [businessDetailsForm, setBusinessDetailsForm] = useState({
    business_name: "",
    abn: "",
    email: "",
    contact_number: "",
    location: "",
    post_code: "",
  });

  useEffect(() => {
    if (data?.user) {
      setAccountHolderForm({
        first_name: data?.user?.first_name || "",
        last_name: data?.user?.last_name || "",
        email: data?.user?.email || "",
        contact_number: data?.user?.contact_number || "",
        state: data?.user?.state_id || "",
        post_code: data?.user?.post_code || "",
      });
    }
  }, [data?.user]);

  // Update state when data becomes available
  useEffect(() => {
    if (data?.branch) {
      setBusinessDetailsForm({
        business_name: data.branch.business_name || "",
        abn: data.branch.abn || "",
        email: data.branch.email || "",
        contact_number: data.branch.contact_number || "",
        location: data.branch.location || "",
        post_code: data.branch.post_code || "",
      });
    }
  }, [data?.branch]);

  // Function to handle account holder details submit
  const handleSubmitAccountHolderDetails = (e) => {
    e.preventDefault();
    // Perform validation or API call
    console.log("Submitting Account Holder Details", businessDetailsForm);
    // API call logic
  };

  const handleSubmitAccountDetails = async (e) => {
    e.preventDefault();

    if(validateAccountHolderForm()){
    const stateName = accountHolderForm.state; // The name of the state from the form
    const selectedState = statesData.find((state) => state.name === stateName);

    const selectedStateId = selectedState ? selectedState.id : null;

    const transformedPayload = {
      contact_number: accountHolderForm.contact_number,
      profile: {
        first_name: accountHolderForm.first_name,
        last_name: accountHolderForm.last_name,
        post_code: accountHolderForm.post_code,
        state: accountHolderForm.state,
      },
    }
    try {
      const response = await submitUserEditForm({
        transformedPayload,
        userId,
      }).unwrap();
      Swal.fire({
        title: "",
        text: "Account holder details updated successfully!",
        icon: "success"
      });
      refetch();
    } catch (err) {
      Swal.fire({
        title: "",
        text: "Updation failed",
        icon: "error"
      });
    }
  }
  else{
    Swal.fire({
      title: "",
      text: "Please fill all the mandatory fields",
      icon: "error"
    });
  } 
  };

  const handleSubmitBusinessDetails = async (e) => {
    e.preventDefault();
       if(validateBusinessForm()){
    if (mainBranchlat) businessDetailsForm.latitude = mainBranchlat;
    if (mainBranchlng) businessDetailsForm.longitude = mainBranchlng;
    try {
      const response = await submitForm({ businessDetailsForm, id, userId }).unwrap();
      Swal.fire({
        title: "",
        text: "Business details updated successfully!",
        icon: "success"
      });
      refetch();
    } catch (err) {
      Swal.fire({
        title: "",
        text: "Updation failed",
        icon: "error"
      });
    }
  }
  else{
    Swal.fire({
      title: "",
      text: "Please fill all mandatory fields",
      icon: "error"
    });
  }
  };

  const [onlinePreferenceForm, setonlinePreferenceForm] = useState({
    weburl: "",
    instagram: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    tiktok: "",
    youtube: "",
  });

  // Update state when data becomes available
  useEffect(() => {
    if (data?.branch) {
      setonlinePreferenceForm({
        weburl: data.branch.weburl || "",
        instagram: data.branch.instagram || "",
        facebook: data.branch.facebook || "",
        linkedin: data.branch.linkedin || "",
        twitter: data.branch.twitter || "",
        youtube: data.branch.youtube || "",
        tiktok: data.branch.tiktok || "",
      });
    }
  }, [data?.branch]);

  const handleSubmitOlinepreferenceDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await submitForm({
        businessDetailsForm: onlinePreferenceForm,
        userId,
        id,
      }).unwrap();
      alert("Form submitted succesfully");
      refetch();
    } catch (err) {
      console.error("Failed to submit form:", err);
      alert("Enter Valid url");
    }
  };

  const handleChangeUserDetails = (e) => {
    const { name, value } = e.target;
    setAccountHolderForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessDetailsForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleInputChangeOnlinepreference = (e) => {
    const { name, value } = e.target;
    setonlinePreferenceForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleOptionChange = (id) => {
    // if (selectedOptions.includes(option)) {
    //   setSelectedOptions(selectedOptions.filter((item) => item !== option));
    // } else {
    //   setSelectedOptions([...selectedOptions, option]);
    // }
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleFileValidation = (file, allowedTypes) => {
    const fileType = file?.type?.split("/")[1].toLowerCase();
    return allowedTypes.includes(fileType);
  };

  const handlelogoChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 300 * 1024) {
      alert("File size exceeds 300 KB. Please select a smaller file.");
      setSelectedLogo(""); // Clear the selected file
      return;
    }


    setSelectedLogo(file);
    if (handleFileValidation(file, ["jpg", "png", "svg", "jpeg"])) {
      setSelectedLogo(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
      setSelectedLogo("");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length < 4) {
      alert("Please select at least 4 images.");
      return;
    }

    if (files.length > 10) {
      alert("You can only upload a maximum of 10 images.");
      return;
    }

    // Validate file types
    const validFiles = files.filter((file) =>
      ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(
        file.type
      )
    );

    if (validFiles.length !== files.length) {
      alert("Only .jpg, .png, and .svg files are allowed.");
      return;
    }

    // Update state with valid files
    setselectedImage(validFiles);
  };

  const handleChangeMiniBio = (e) => {
    setMinibio(e.target.value);
  }

  const handleSubmitAdditionalData = async (e) => {
    e.preventDefault();

    const postData = new FormData();
    if (selectedLogo) postData.append("logo", selectedLogo);
    if (selectedMenu) postData.append("menu", selectedMenu);

    if (selectedImage) {
      selectedImage.forEach((item) => postData.append("images", item));
    }

    selectedOptions.forEach((item) => postData.append("service_categories", item));
    console.log("data?.additional_detail", data?.additional_detail)

    if (selectedMinibio) {
      postData.append("min_bio", selectedMinibio)
    }

    try {
      const response = await submitAdditionalData({
        postData,
        branchid: id,
        userId,
        id: data?.additional_detail?.id,
      }).unwrap();
      if (response) {
        alert("Form submitted succesfully");
        refetch();
      }
    } catch (err) {
      alert("Failed to submit form");
    }
  };

  const handleSubmitEcosystemCritera = async (e) => {
    e.preventDefault();
    let postData = {
      ce_features: selectedFeatures,
      ce_menu_highlights: selectedMenuHighlights,
    };

    if (operationalStatus.length > 0) {
      postData.ce_operational_status = operationalStatus;
    }

    try {
      const response = await submitEcosystemCriteria({ postData, branchid: id, userId, id: data?.ecosystem_criteria?.id }).unwrap();
      if (response) {
        alert("form submitted succesfully");
        refetch();
      }
    } catch (err) {
      alert("Failed to submit form");
    }
  };

  const [selectedOperationalStatus, setSelectedOperationalStatus] = useState({});
  const [operationalStatus, setOperationalStatus] = useState([]);

  const handleChangeStatus = (e) => {
    const { value } = e.target;

    setSelectedOperationalStatus((prevState) => {
      const updatedOptions = {
        ...prevState,

        [value]: !prevState[value],
      };

      const updatedStatus = [];
      if (updatedOptions.planning) updatedStatus.push(1);
      if (updatedOptions.open) updatedStatus.push(2);
      if (updatedOptions.exiting) updatedStatus.push(3);

      setOperationalStatus(updatedStatus);

      return updatedOptions;
    });
  };


  const onMainPlacesChanged = () => {
    const places = searchBoxMainRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat().toFixed(6);
      const lng = place.geometry.location.lng().toFixed(6);

      // Update the latitude and longitude
      setMainbranchlat(lat);
      setMainbranchlng(lng);

      // Update the address if place exists
      if (place) {
        const address = place.formatted_address; // Get the formatted address
        setMainAddress(address);

        // Update formData with the new location
        setBusinessDetailsForm({
          ...businessDetailsForm,
          location: address, // Use 'address' for updating location
        });
      }
    }
  };


  const validateBusinessForm = () => {
    const newErrors = {};

    if (!businessDetailsForm.business_name.trim()) {
      newErrors.business_name = "Business name is required.";
    }
    if (!businessDetailsForm.email.trim()) {
      newErrors.email = "Email is required.";
    }
    if (!businessDetailsForm.contact_number.trim()) {
      newErrors.contact_number = "Contact number is required.";
    }
    if (!businessDetailsForm.location.trim()) {
      newErrors.location = "Location is required.";
    }
    if (!businessDetailsForm.post_code.trim()) {
      newErrors.post_code = "Post code is required.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const validateAccountHolderForm = () => {
    const newErrors = {};
  
    if (!accountHolderForm.first_name) {
      newErrors.first_name = "First name is required.";
    }
    if (!accountHolderForm.last_name) {
      newErrors.last_name = "Last name is required.";
    }
    if (!accountHolderForm.email) {
      newErrors.email = "Email is required.";
    }
    if (!accountHolderForm.contact_number) {
      newErrors.contact_number = "Contact number is required.";
    }
    if (!accountHolderForm.state) {
      newErrors.state = "State is required.";
    }
    if (!accountHolderForm.post_code) {
      newErrors.post_code = "Post code is required.";
    }
  
    setErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };
  

  return (
    <div className="user-details-tab-content">
      <form>
        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3>Account holder details</h3>
          </Col>
        </Row>

        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  First name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.first_name}
                  name="first_name"
                  placeholder="First name"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Last name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.last_name}
                  name="last_name"
                  placeholder="Last name"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Email<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.email}
                  name="email"
                  placeholder="Email"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Contact number<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.contact_number}
                  name="contact_number"
                  placeholder="Contact number"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  State<span className="red-star">*</span>
                </label>
                <br />
                <select disabled={isViewAccountHolderDetails} name="state" value={accountHolderForm?.state} onChange={handleChangeUserDetails}>
                <option value="" disabled>Please select location</option>
                  {statesData?.map((state) => (
                    <option key={state.id} value={state.id}>{state.name}</option>
                  ))}
                </select>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Post code<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={accountHolderForm?.post_code}
                  name="post_code"
                  placeholder="Post code"
                  disabled={isViewAccountHolderDetails}
                  onChange={handleChangeUserDetails}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="mt-20">
          {isViewAccountHolderDetails ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEditAccountHolderDetails}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelAccountHolderDetails}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                onClick={handleSubmitAccountDetails}
                type="button"
              >
                Submit
              </button>
            </Col>
          )}
        </Row>

        <hr className="bold-birder-hr" />

        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3 className="mt-20">Business details</h3>
          </Col>
        </Row>

        <hr />
        <div>
          {data?.branch?.id  ? (<Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Business name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.business_name}
                  name="business_name"
                  placeholder="Business name"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>ABN</label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.abn}
                  name="abn"
                  placeholder="ABN"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Email<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.email}
                  name="email"
                  placeholder="Email"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Contact number<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.contact_number}
                  name="contact_number"
                  placeholder="Contact number"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Location<span className="red-star">*</span>
                </label>
                <br />
                {/* <input
                  type="text"
                  value={businessDetailsForm.location}
                  name="location"
                  placeholder="Location"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                /> */}
                 <StandaloneSearchBox
                onLoad={(ref) => (searchBoxMainRef.current = ref)}
                onPlacesChanged={onMainPlacesChanged}
              >
                <input
                  type="text"
                  placeholder="Enter location"
                  className="autocomplete-input"
                  style={{ width: "100%", padding: "10px" }}
                  required
                  value={businessDetailsForm.location}
                  onChange={(e) =>
                    setBusinessDetailsForm({
                      ...businessDetailsForm,
                      location: e.target.value,
                    })
                  }
                />
              </StandaloneSearchBox>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Post code<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={businessDetailsForm.post_code}
                  name="post_code"
                  placeholder="Post code"
                  disabled={isViewbusinessDetails}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>) : (<Row><p>No data found.</p></Row>)}
        </div>

        {data?.branch?.id  && <Row className="mt-20">
          {isViewbusinessDetails ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEDitBusinessDetails}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelBusinessDetails}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                type="button"
                onClick={handleSubmitBusinessDetails}
              >
                Submit
              </button>
            </Col>
          )}
        </Row>}
        <hr className="bold-birder-hr" />

        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3 className="mt-20">Online Presence</h3>
          </Col>
        </Row>

        <hr />
        <div>
          {data?.branch?.id  ? (<Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Web URL</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm.weburl}
                  name="weburl"
                  placeholder="Web url"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Instagram</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.instagram}
                  name="instagram"
                  placeholder="Instagram"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Facebook</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.facebook}
                  name="facebook"
                  placeholder="Facebook"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>LinkedIn</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.linkedin}
                  name="linkedin"
                  placeholder="linkedin"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Twitter</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.twitter}
                  name="twitter"
                  placeholder="Twitter"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>TikTok</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.tiktok}
                  name="tiktok"
                  placeholder="TikTok"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>YouTube</label>
                <br />
                <input
                  type="url"
                  value={onlinePreferenceForm?.youtube}
                  name="youtube"
                  placeholder="YouTube"
                  disabled={isViewonlinePreference}
                  onChange={handleInputChangeOnlinepreference}
                />
              </div>
            </Col>
          </Row>) : (<Row><p>No data found.</p></Row>)}
        </div>

        {data?.branch?.id  && <Row className="mt-20">
          {isViewonlinePreference ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEDitOnlinePreference}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelOnlinePreference}
                type="button"
              >
                Cancel
              </button>
              <button
                className="edit-btn btn-primary"
                type="button"
                onClick={handleSubmitOlinepreferenceDetails}
              >
                Submit
              </button>
            </Col>
          )}
        </Row>}

        <hr className="bold-birder-hr" />

        <Row className="mt-20">
          <Col sm={12} md={6} lg={6}>
            <h3 className="mt-20">Details</h3>
          </Col>
        </Row>

        <hr />
        <div>
          {data?.additional_detail?.id ? (<Row>
            {/* {data?.additional_detail?.logo && ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Logo</label>
                <br />
                <img
                  src={data?.additional_detail?.logo}
                  alt="defaultplaceholder"
                  className="uploaded-images"
                />
              </div>
              <input
                type="file"
                className="file-upload-input"
                onChange={handlelogoChange}
                disabled={isViewDetails}
              />
            </Col>
            {/* )} */}

            {/* {data?.additional_detail?.images.length > 0 && ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Images</label>
                <br />

                {data?.additional_detail?.images.map((img) => (
                  <img
                    src={img.image}
                    alt="defaultplaceholder"
                    className="uploaded-images"
                  />
                ))}
              </div>
              <input
                type="file"
                className="file-upload-input"
                onChange={handleImageChange}
                disabled={isViewDetails}
                multiple
              />
            </Col>
            {/* )} */}
            <Col sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>Mini Bio</label>
                <br />
                <textarea
                  className="text-area-min-bio"
                  disabled={isViewDetails}
                  value={selectedMinibio}
                  onChange={handleChangeMiniBio}
                />
              </div>
            </Col>
          </Row>) : (<Row><p>No data found.</p></Row>)}
        </div>
        {data?.additional_detail?.id && <Row className="mt-20">
          {isViewDetails ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEDitDetails}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelDetails}
                type="button"
              >
                Cancel
              </button>
              <button className="edit-btn btn-primary" type="button" onClick={handleSubmitAdditionalData}>
                Submit
              </button>
            </Col>
          )}
        </Row>}
        <div>

        <hr className="bold-birder-hr" />

          <Row>
            <Col sm={12} md={6} lg={6}>
              <h3 className="mt-20">Additional Details</h3>
            </Col>
          </Row>

          <hr />
          {data?.additional_detail?.id ? (<Row>
            <Col xs={12} sm={12} lg={6} md={6}>
              <div className="form-control">
                <label className="choose-label">
                  Service Category
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {serviceData?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className={`multi-select-container ${selectedOptions.includes(option.id) ? "selected" : ""
                          }`}
                        onClick={() => handleOptionChange(option.id)}
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedOptions.includes(option.id)}
                          onChange={() => handleOptionChange(option.id)}
                          style={{ display: "none" }} // Hide the checkbox
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label
                          htmlFor={`option${option.id}`}
                          title={option.name} // Show full name on hover
                        >
                          {option.name.length > 10
                            ? `${option.name.substring(0, 10)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
          </Row>) : (<Row><p>No data found.</p></Row>)}
        </div>
        <div>
  

          <hr />
          {data?.additional_detail?.id ? (<Row className="news-reviews">
            <Col md={6} lg={6} sm={12}>
              <h5>Upcoming events</h5>
              <hr />
              {data?.additional_detail?.events?.map((event, index) => (
                <div
                  key={event.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <a
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.title}
                    </a>
                  </p>
                </div>
              ))}
            </Col>

            <Col md={6} lg={6} sm={12}>
              <h5>Online reviews</h5>
              <hr />
              {data?.additional_detail?.reviews?.map((review, index) => (
                <div
                  key={review.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                  <img
                       src={
                        review.platform_name === "Google Reviews"
                          ? GoogleImg
                          : review.platform_name === "Trustpilot"
                          ? Trustpilot
                          : FaceBookImg
                      }
                        alt={review.platform_name}
                      />
                    <a
                      href={review.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {review.platform_name}
                    </a>
                  </p>
                </div>
              ))}
            </Col>
          </Row>) : (<Row><p>No data found.</p></Row>)}
          {data?.additional_detail?.id && <Row className="mt-20">
            {isViewAdditionalDetails ? (
              <Col sm={12} md={12} lg={12} className="text-end">
                <button
                  className="edit-btn btn-primary"
                  onClick={handleClickEDiAdditionalDetails}
                  type="button"
                >
                  Edit
                </button>
              </Col>
            ) : (
              <Col sm={12} md={12} lg={12} className="text-end">
                <button
                  className="btn-outline-edit"
                  onClick={handleClickCancelAdditionalDetails}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="edit-btn btn-primary"
                  type="button"
                  onClick={handleSubmitAdditionalData}
                >
                  Submit
                </button>
              </Col>
            )}
          </Row>}
        </div>
        <div>

        <hr className="bold-birder-hr" />
          <Row className="mt-20">
            <Col sm={12} md={6} lg={6}>
              <h3 className="mt-20">Ecosystem Criteria</h3>
            </Col>
          </Row>

          <hr />
          {data?.ecosystem_criteria?.id ? (<Row>
            <h5 className="mb-20 mt-20">Cafe</h5>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Preferred operational status
                  <span className="red-star">*</span>
                </label>
                <br />

                <div className="">
                  <div className="checkbox-group">
                    <label className="deleivery-yes">
                      <input
                        type="checkbox"
                        id="planning"
                        name="confirmation_status"
                        value="planning"
                        onChange={handleChangeStatus}
                        checked={selectedOperationalStatus.planning}
                      />
                      <span className="check-delivery-text">Planning to open</span>
                    </label>
                    <label className="deleivery-yes">
                      <input
                        type="checkbox"
                        id="open"
                        name="confirmation_status"
                        value="open"
                        onChange={handleChangeStatus}
                        checked={selectedOperationalStatus.open}
                      />
                      <span className="check-delivery-text">Open for business</span>
                    </label>
                    <label className="deleivery-yes">
                      <input
                        type="checkbox"
                        id="exiting"
                        name="confirmation_status"
                        value="exiting"
                        onChange={handleChangeStatus}
                        checked={selectedOperationalStatus.exiting}
                      />
                      <span className="check-delivery-text">Looking to exit</span>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Features (Amenities and Products)
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {/* {featuresData?.map((option) => ( */}
                  <div className="multi-select-wrapper">
                    <div className="multi-select-wrapper">
                      {featureData?.map((option) => {
                        // Debugging: Log each feature option and the selectedFeatures


                        return (
                          <div key={option.id} className="multi-select-item">
                            <div
                              className={`multi-select-container ${selectedFeatures.includes(option.id)
                                ? "selected"
                                : ""
                                }`}

                              onClick={() =>
                                !isViewEcosystemCriteria &&
                                handleFeatureChange(option.id)
                              }
                            >
                              <input
                                type="checkbox"
                                id={`option${option.id}`}
                                name="services"
                                value={option.id}
                                // Ensure type consistency here
                                checked={selectedFeatures.includes(option.id)}
                                onChange={() => handleFeatureChange(option.id)}
                                disabled={isViewAdditionalDetails}
                                style={{ display: "none" }}
                              />
                              <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                              <label
                                htmlFor={`option${option.id}`}
                                title={option.name} // Show full name on hover
                              >
                                {option.name.length > 10
                                  ? `${option.name.substring(0, 10)}...`
                                  : option.name}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Menu Highlights
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {menuhighlightData?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className={`multi-select-container ${selectedMenuHighlights.includes(option.id)
                          ? "selected"
                          : ""
                          }`}


                        onClick={() =>
                          !isViewEcosystemCriteria &&
                          handleMenuHighlightChange(option.id)
                        }
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="products"
                          value={option.id}
                          checked={selectedMenuHighlights.includes(option.id)}
                          onChange={() => handleMenuHighlightChange(option.id)}
                          style={{ display: "none" }}
                          disabled={isViewAdditionalDetails}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label
                          htmlFor={`option${option.id}`}
                          title={option.name}
                        >
                          {option.name.length > 10
                            ? `${option.name.substring(0, 10)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
          </Row>) : (<Row><p>No data found.</p></Row>)}
        </div>
        {data?.ecosystem_criteria?.id && <Row className="mt-20">
          {isViewEcosystemCriteria ? (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="edit-btn btn-primary"
                onClick={handleClickEcosystemCriteria}
                type="button"
              >
                Edit
              </button>
            </Col>
          ) : (
            <Col sm={12} md={12} lg={12} className="text-end">
              <button
                className="btn-outline-edit"
                onClick={handleClickCancelEcosystemCriteria}
                type="button"
              >
                Cancel
              </button>
              <button className="edit-btn btn-primary" type="button" onClick={handleSubmitEcosystemCritera}>
                Submit
              </button>
            </Col>
          )}
        </Row>}
      </form>
    </div>
  );
}

export default CafeDetails;
